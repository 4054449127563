import React from "react";
import styles from "./styles.module.scss";
import Flag from "@assets/img/flag.svg";
import Copy from "@assets/img/Vector.svg";

interface ProductCodeProps {
  code: string;
}

const ProductCode: React.FC<ProductCodeProps> = ({ code }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(code);
  };

  return (
    <div className={styles.productCode} onClick={copyToClipboard}>
      <img
        src={Flag}
        alt="USA Flag"
        className={styles.flag}
        width={13}
        height={11}
      />
      <span className={styles.code}>{code}</span>
      <img
        src={Copy}
        alt="Copy Icon"
        className={styles.copyIcon}
        width={12}
        height={12}
      />
    </div>
  );
};

export default ProductCode;
