import PageWrapper from "@components/PageWrapper/PageWrapper";
import Intro from "./components/Intro";
import Analysis from "./components/Analysis";
import AiPlatformSlider from "./components/AiPlatformSlider";
import { WelcomeSection } from "@components/WelcomeSection";

function Welcome() {
  return (
    <PageWrapper>
      <Intro />
      <WelcomeSection />
      <Analysis />
      <AiPlatformSlider />
    </PageWrapper>
  );
}

export default Welcome;
