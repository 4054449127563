import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";

export const widgetMocks: IWidget[] = [
  {
    id: "1",
    name: "Orders",
    value: "59",
    difference: "+0.02",
  },
  {
    id: "2",
    name: "Total Sales",
    value: "$24470.1",
    difference: "-$345.2",
  },
  {
    id: "3",
    name: "Units sold",
    value: "33",
    difference: "+17%",
  },
  {
    id: "4",
    name: "Contribution profit",
    value: "$6336",
    difference: "+2%",
  },
  {
    id: "5",
    name: "Organic CTR",
    value: "0.11%",
    difference: "+0.02%",
  },
  {
    id: "6",
    name: "Organic CVR",
    value: "0.12%",
    difference: "+0.02%",
  },
  {
    id: "7",
    name: "Review score",
    value: "3.5",
    difference: "+0.1%",
  },
  {
    id: "8",
    name: "Value from Nyle",
    value: "$3878.2",
    difference: "+0.1%",
  },
];
