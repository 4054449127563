import Photo from "@assets/img/Photo.png";
import Photo2 from "@assets/img/Photo2.png";
import Photo3 from "@assets/img/Photo3.png";
import Photo4 from "@assets/img/Photo4.png";

export interface User {
  name: string;
  title: string;
  photo: string;
}

export const users: User[] = [
  { name: "John Gold", title: "CEO", photo: Photo },
  { name: "John Gold", title: "CMO", photo: Photo2 },
  { name: "John Gold", title: "COO", photo: Photo3 },
  { name: "John Gold", title: "CFO", photo: Photo4 },
];
