import React from "react";
import styles from "./styles.module.scss";
import Aspects from "./Aspect";
import Phases from "./Phases";

const AspectsAndPrases: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.aspectsContainer}>
        <Aspects />
      </div>
      <div className={styles.pharesContainer}>
        <Phases />
      </div>
    </div>
  );
};

export default AspectsAndPrases;
