export enum ROUTES {
  ROOT = "/",
  OVERVIEW = "/overview",
  FINANCE = "/finance",
  AUTH_ACTIVATE = "activate/:uid/:token",
  AUTH = "/auth",
  WELCOME = "/welcome",
  AUTH_LOGIN = "login",
  AUTH_FORGOT = "forgot",
  AUTH_SIGNUP = "signup",
  AUTH_CONFIRMED = "/confirmed",
  AUTH_PASSWORDCONFIRMED = "/password-confirmed",
  AUTH_SIGNUPSUCCESSFUL = "successful-registration",
  AUTH_PASSWORDRESET = "/password/reset/confirm",
  AUTH_CALLBACK = "callback",
  AMAZON_ONBOARDING = "amazon-onboarding",
  AMAZON_ONBOARDING_COMPLETE = "amazon-onboarding-complete",
  CONNECT_AMAZON = "connect-amazon",
  REDIRECT_TO_SELLER = "redirect-to-seller",
  REDIRECT_TO_AMAZON = "/redirect-to-amazon",
  CONGRATULATIONS_CONNECT = "/congratulations-connect",
  SETTINGS_ACCOUNT = "/settings/account",
  SETTINGS_PLAN = "/settings/plan",
  SETTINGS_TEAM = "/settings/team",
}
