import CameraIcon from "@assets/icons/camera";
import styles from "./styles.module.scss";
import amazonIcon from "@assets/img/amazon.png";
import { ROUTES } from "src/router/routes";
import AuthButtonArrow from "@assets/icons/authButtonArrow";
import { Link } from "react-router-dom";

function ConnectAmazon() {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.heading}>
            <h1>Connect to Amazon</h1>
            <button>
              learn
              <CameraIcon />
            </button>
          </div>
          <div className={styles.boxes}>
            <div className={styles.box}>
              <div className={styles.box__inner}>
                <h2>Connect Your Seller Accounts</h2>
                <p>
                  Once connected, you can automate your product review requests,
                  track your financial data, monitor your products and detect
                  hijackers.
                </p>
                <Link to={`/${ROUTES.AMAZON_ONBOARDING}`} className="button">
                  Connect Your Seller Account <AuthButtonArrow />
                </Link>
              </div>
            </div>
            <div className={styles.box}>
              <div className={styles.box__inner}>
                <h2>Connect Your Amazon Ads Account</h2>
                <p>
                  Once connected, you can track your Ad spend, examine Ad
                  campaigns performance and automate your Amazon Ad campaigns.{" "}
                </p>
                <Link to={ROUTES.REDIRECT_TO_AMAZON} className="button">
                  Connect Your Ads Accounts <AuthButtonArrow />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <img src={amazonIcon} alt="amazon" />
      </div>
    </div>
  );
}

export default ConnectAmazon;
