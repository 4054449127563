import React from "react";
import CustomCheckbox from "@components/CustomCheckbox";
import styles from "./styles.module.scss";
import InfoIcon from "@assets/icons/i.svg";

interface MetricItemProps {
  metric: {
    id: string;
    title: string;
  };
  checked: boolean;
  onChange: () => void;
}

const MetricItem: React.FC<MetricItemProps> = ({
  metric,
  checked,
  onChange,
}) => {
  return (
    <div className={styles.metricItem} onClick={onChange}>
      <CustomCheckbox
        checked={checked}
        onChange={(e) => {
          e.stopPropagation();
          onChange();
        }}
      />
      <p className={styles.metricTitle}>
        {metric.title}
        <img
          src={InfoIcon}
          alt="Info"
          className={styles.infoIcon}
          width={14}
          height={14}
        />
      </p>
    </div>
  );
};

export default MetricItem;
