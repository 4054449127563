import React from "react";
import PageWrapper from "@components/PageWrapper/PageWrapper";
import Header from "./components/Header";
import ProductInfo from "./components/ProductInfo";
import TopProblems from "./components/TopProblems";
import Reviews from "./components/Reviews";
import TopPerformance from "./components/TopPerformance";
import Aspects from "./components/AspectsAndPrases";
import ReviewScore from "./components/ReviewScore";

export const Overview: React.FC = () => {
  return (
    <PageWrapper>
      <Header />
      <ProductInfo />
      <ReviewScore />
      <Aspects />
      <TopProblems />
      <Reviews />
      <TopPerformance />
    </PageWrapper>
  );
};
