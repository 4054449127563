import ChevronDown from "@assets/icons/chevron-down.tsx";
import { IUserState, useUserStore } from "src/store/user.state.ts";
import { Skeleton } from "@components/ui-kit/Skeleton";
import { useEffect, useRef, useState } from "react";
import { UserPopup } from "./UserPopup/UserPopup";

export const HeaderAvatar = () => {
  const user = useUserStore((state: IUserState) => state.user);
  const userLoaded = useUserStore((state: IUserState) => state.userLoaded);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  if (!userLoaded) {
    return (
      <div className="app-header__avatar">
        <Skeleton width="160px" height="40px" shape="round" />
      </div>
    );
  }

  const initials = `${user.first_name && user.first_name[0]}${user.last_name && user.last_name[0]}`;

  return (
    <div
      className={`app-header__avatar ${menuOpen ? `active-menu` : ``}`}
      ref={popupRef}
    >
      <div className={`nyle-avatar ${menuOpen ? `active-avatar` : ""} `}>
        <div
          className="nyle-avatar__image text-large"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {initials}
        </div>
        <div
          className="nyle-avatar__details"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <div
            className={`nyle-avatar__details-text body-large ${menuOpen ? `activeName` : ``}`}
          >
            {user.first_name}
          </div>
          <div
            className={`nyle-avatar__details-subtext body-small ${menuOpen ? `activeCto` : ``}`}
          >
            CTO
          </div>
        </div>
        <div
          className="nyle-avatar__icon"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <ChevronDown />
        </div>
        {menuOpen && <UserPopup closeMenu={closeMenu} />}
      </div>
    </div>
  );
};
