import "./index.css";
import { ProductTab } from "./product-tab";
import { useState } from "react";
import Slider from "./slider";
import Table from "./table";

export const Products = () => {
  const [selectedTab, setSelectedTab] = useState<string>("tiles");
  return (
    <div className="products-section">
      <ProductTab setSelectedTab={(el: string) => setSelectedTab(el)} />
      {selectedTab === "tiles" ? <Slider /> : <Table />}
    </div>
  );
};
