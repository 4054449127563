import { createContext, useState, useEffect, ReactNode } from "react";
import { ConfigService, IConfig } from "@services/config.service";

export const NyleConfigContext = createContext<IConfig | null>(null);

interface NyleConfigProviderProps {
  children: ReactNode;
}

export const NyleConfigProvider = ({ children }: NyleConfigProviderProps) => {
  const [config, setConfig] = useState<IConfig | null>(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const fetchedConfig = await ConfigService.fetchConfig();
        setConfig(fetchedConfig);
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };
    fetchConfig();
  }, []);

  return (
    <NyleConfigContext.Provider value={config}>
      {children}
    </NyleConfigContext.Provider>
  );
};
