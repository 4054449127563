import React, { useState, useEffect, useRef } from "react";
import ModalBox from "@components/ModalBox";
import { productSelectionMocks } from "@pages/Overview/mocks/productSelectionMocks";
import styles from "./styles.module.scss";
import MockPhoto from "@assets/img/photo.svg";
import SearchIcon from "@assets/icons/SearchIcon.svg";
import CheckIcon from "@assets/icons/CheckIcon.svg";
import dropDownArrow from "@assets/icons/inputArrow.svg";
import ProductCode from "@components/ProductCode";

interface ProductModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ProductSelectionModal: React.FC<ProductModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [activeTab, setActiveTab] = useState("myProducts");
  const [activeButton, setActiveButton] = useState("16");
  const [selectedProductIndex, setSelectedProductIndex] = useState<
    number | null
  >(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(
    productSelectionMocks,
  );

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredProducts(productSelectionMocks);
    } else {
      setFilteredProducts(
        productSelectionMocks.filter((product) =>
          product.asin.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    }
  }, [searchTerm]);

  const handleSelect = (index: number) => {
    setSelectedProductIndex(index);
  };

  return (
    <ModalBox isOpen={isOpen} onClose={onClose} title="Product Selection">
      <div className={styles.tabButtons}>
        <div className={styles.tabButtonsWrapper}>
          <button
            className={`${styles.tabButton} ${activeTab === "myProducts" ? styles.active : ""}`}
            onClick={() => setActiveTab("myProducts")}
          >
            MY PRODUCTS
          </button>
          <button
            className={`${styles.tabButton} ${activeTab === "allProducts" ? styles.active : ""}`}
            onClick={() => setActiveTab("allProducts")}
          >
            ALL PRODUCTS
          </button>
        </div>
      </div>
      <div className={styles.searchBox}>
        <input
          type="text"
          placeholder="Search by ASIN"
          className={styles.searchInput}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className={styles.searchButton}>
          <img src={SearchIcon} alt="Search" />
        </button>
      </div>

      <div className={styles.sortSection}>
        <div className={styles.sortSelect}>
          <span className={styles.sortText}>SORT ALPHABETICALLY</span>
          <img
            src={dropDownArrow}
            alt="Sort"
            className={styles.dropDownArrow}
          />
        </div>
        <div className={styles.switchButtons}>
          <button
            className={`${styles.switchButton} ${activeButton === "16" ? styles.active : ""}`}
            onClick={() => setActiveButton("16")}
          >
            16
          </button>
          <button
            className={`${styles.switchButton} ${activeButton === "32" ? styles.active : ""}`}
            onClick={() => setActiveButton("32")}
          >
            32
          </button>
          <button
            className={`${styles.switchButton} ${activeButton === "64" ? styles.active : ""}`}
            onClick={() => setActiveButton("64")}
          >
            64
          </button>
        </div>
      </div>
      <div className={styles.productGrid}>
        {filteredProducts.map((product, index) => (
          <div key={index} className={styles.productCard}>
            <img
              src={MockPhoto}
              alt={product.name}
              className={styles.productImage}
              width={124}
              height={124}
            />
            <ProductName name={product.name} />
            <div className={styles.productDetails}>
              <span className={styles.productRating}>⭐{product.rating}</span>
              <ProductCode code={product.asin} />
            </div>
            <button
              className={`${styles.selectButton} ${selectedProductIndex === index ? styles.selected : ""}`}
              onClick={() => handleSelect(index)}
            >
              {selectedProductIndex === index ? (
                <>
                  <img
                    src={CheckIcon}
                    alt="Selected"
                    className={styles.checkIcon}
                  />
                  SELECT
                </>
              ) : (
                "SELECT"
              )}
            </button>
          </div>
        ))}
      </div>
      <button className={styles.loadMoreButton}>LOAD MORE</button>
    </ModalBox>
  );
};

const ProductName: React.FC<{ name: string }> = ({ name }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const productNameRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const element = productNameRef.current;
    if (element) {
      setIsTruncated(element.scrollHeight > element.clientHeight);
    }
  }, [name]);

  return (
    <p
      ref={productNameRef}
      className={`${styles.productName} ${isTruncated ? styles.truncated : ""}`}
    >
      {name}
    </p>
  );
};

export default ProductSelectionModal;
