import { SALES_PERFORMANCE_METRICS } from "../../constants";
import { useMemo } from "react";

interface Payload {
  name: string;
  value: number;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: Payload[];
}

export const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
}) => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  const uniquePayload = useMemo(() => {
    const visited: { [key: string]: boolean } = {};
    const result: Payload[] = [];

    payload.forEach((p) => {
      if (!visited[p.name]) {
        visited[p.name] = true;
        result.push(p);
      }
    });

    return result;
  }, [payload]);

  const payloadJSX = uniquePayload.map((p, i) => {
    return (
      <div key={i} className="performance-tooltip__row">
        <div className="performance-tooltip__rowLabel body-small">
          {SALES_PERFORMANCE_METRICS[p.name] || p.name}
        </div>
        <div className="performance-tooltip__rowValue body-small">
          ${p.value}
        </div>
      </div>
    );
  });

  return <div className="performance-tooltip">{payloadJSX}</div>;
};
