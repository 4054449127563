import "./index.css";
import {
  IDashboardState,
  useDashboardStore,
} from "@pages/Dashboard/store/dashboard.state.ts";
import { Header } from "./Header";
import { WidgetsList } from "./WidgetsList";

export const Widgets = () => {
  const widgets = useDashboardStore((state: IDashboardState) => state.widgets);

  return (
    <div className="dashboard-widgets">
      <Header />
      <WidgetsList widgets={widgets} />
    </div>
  );
};
