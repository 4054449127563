import { create } from "zustand";
import { ConfigService, IConfig } from "@services/config.service";

interface ConfigState {
  config: IConfig | null;
  fetchConfig: () => Promise<void>;
}

export const useConfigStore = create<ConfigState>((set) => ({
  config: null,
  fetchConfig: async () => {
    try {
      set({ config: await ConfigService.fetchConfig() });
    } catch (error) {
      console.error("Error fetching config:", error);
    }
  },
}));
