import clsx from "clsx";
import { useTranslation } from "react-i18next";
import ConfigImage from "@assets/icons/config.svg";
import CalendarImage from "@assets/icons/calendar.svg";
import { useEffect, useRef, useState } from "react";
import { ConfigModal } from "@components/config-modal";
import { CalendarModal } from "@components/calendar-modal";
import styles from "./styles.module.scss";

interface Tab {
  label: string;
  code: string;
  icon: string;
}

const tabList: Tab[] = [
  {
    label: "Configure",
    code: "config",
    icon: ConfigImage,
  },
  {
    label: "01.01.24 — 07.01.24",
    code: "calendar",
    icon: CalendarImage,
  },
];

export const Tab = () => {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState<string>("");
  const [modalPosition, setModalPosition] = useState<{
    x: number;
    y: number;
    width: number;
  }>({ x: 0, y: 0, width: 0 });
  const [selectedDateRange, setSelectedDateRange] = useState<string>(
    tabList[1].label,
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setActiveTab("");
      }
    };

    if (activeTab) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      if (activeTab) {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };
  }, [activeTab]);

  const toggleTab = (code: string, event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    const newActiveTab = activeTab === code ? "" : code;
    setActiveTab(newActiveTab);

    if (newActiveTab) {
      const buttonRect = event.currentTarget.getBoundingClientRect();
      setModalPosition({
        x: buttonRect.right,
        y: buttonRect.bottom,
        width: buttonRect.width,
      });
    }
  };

  const closeModal = () => {
    setActiveTab("");
  };

  const handleDateChange = (startDate: Date, endDate: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const formattedStartDate = startDate
      .toLocaleDateString("default", options)
      .replace(/\//g, ".");
    const formattedEndDate = endDate
      .toLocaleDateString("default", options)
      .replace(/\//g, ".");
    const formattedDateRange = `${formattedStartDate} — ${formattedEndDate}`;
    setSelectedDateRange(formattedDateRange);
  };

  return (
    <div className={styles.tab}>
      {tabList.map((item) => (
        <div
          key={item.label}
          onClick={(e) => toggleTab(item.code, e)}
          className={clsx(styles.element, {
            [styles["element--active"]]: activeTab === item.code,
          })}
        >
          <img src={item.icon} alt={t(item.label)} className={styles.icon} />
          <p className={styles.tabItem}>
            {item.code === "calendar" ? selectedDateRange : t(item.label)}
          </p>
          {activeTab === item.code && (
            <div
              ref={modalRef}
              className={styles.modalContainer}
              onClick={(e) => e.stopPropagation()}
            >
              {item.code === "config" && (
                <ConfigModal
                  buttonWidth={modalPosition.width}
                  closeModal={closeModal}
                />
              )}
              {item.code === "calendar" && (
                <CalendarModal
                  buttonWidth={modalPosition.width}
                  closeModal={closeModal}
                  onDateChange={handleDateChange}
                />
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
