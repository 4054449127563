import React from "react";
import styles from "./styles.module.scss";
import Settings from "@assets/img/settings";

interface DataBlockProps {
  data: {
    sessionTotal: string;
    pageViewsTotal: string;
    featuredOffer: string;
    unitsOrdered: string;
    unitsSessionPercentage: string;
    orderedProductSales: string;
    totalOrderItems: string;
  };
}

const DataBlock: React.FC<DataBlockProps> = ({ data }) => {
  return (
    <div className={styles.dataBlock}>
      <table className={styles.table}>
        <thead className={styles.headerRow}>
          <tr>
            <th className={styles.headerCell}>SESSION TOTAL</th>
            <th className={styles.headerCell}>PAGE VIEWS TOTAL</th>
            <th className={styles.headerCell}>
              FEATURED OFFER <br /> (BUY BOX) PERCENTAGE
            </th>
            <th className={styles.headerCell}>UNITS ORDERED</th>
            <th className={styles.headerCell}>
              UNITS SESSION <br /> PERCENTAGE
            </th>
            <th className={styles.headerCell}>
              ORDERED <br /> PRODUCT SALES
            </th>
            <th className={styles.headerCell}>
              TOTAL ORDER <br /> ITEMS
            </th>
            <th className={styles.settingsCell}>
              <div>
                <Settings />
              </div>
            </th>
          </tr>
        </thead>
        <tbody className={styles.dataRow}>
          <tr>
            <td className={styles.dataCell}>{data.sessionTotal}</td>
            <td className={styles.dataCell}>{data.pageViewsTotal}</td>
            <td className={styles.dataCell} style={{ color: "#80c67a" }}>
              {data.featuredOffer}
            </td>
            <td className={styles.dataCell}>{data.unitsOrdered}</td>
            <td className={styles.dataCell} style={{ color: "#fe5858" }}>
              {data.unitsSessionPercentage}
            </td>
            <td className={styles.dataCell}>{data.orderedProductSales}</td>
            <td className={styles.dataCell}>{data.totalOrderItems}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DataBlock;
