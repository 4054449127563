import { useEffect } from "react";
import Zendesk from "react-zendesk";
import { useConfigStore } from "src/store/config.state";

const setting = {};

export default function HelpPage() {
  const { config, fetchConfig } = useConfigStore();

  useEffect(() => {
    if (!config) {
      fetchConfig();
    }
  }, [config, fetchConfig]);

  if (!config) {
    return null;
  }

  return (
    <div className="help-page-container">
      <div className="container">
        <Zendesk defer zendeskKey={config.zendesk_key} {...setting} />
      </div>
    </div>
  );
}
