import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import AuthButtonArrow from "@assets/icons/authButtonArrow";
import ResendActivationService from "@services/resendActivationEmail";

function SuccessfulRegistration() {
  const navigate = useNavigate();
  const [resendMessage, setResendMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timer, setTimer] = useState(0);
  const [messageClass, setMessageClass] = useState("");
  const [isSending, setIsSending] = useState(false);

  const handleButtonClick = () => {
    navigate("/auth/login");
  };

  const handleResendClick = async () => {
    const email = localStorage.getItem("registeredEmail");
    if (email) {
      setIsSending(true);
      try {
        await ResendActivationService.resendActivation(email);
        setResendMessage("Activation email has been resent successfully.");
        setMessageClass("success");
        setIsButtonDisabled(true);
        setTimer(30);
      } catch (error) {
        setResendMessage("Failed to resend activation email.");
        setMessageClass("fail");
      }
      setIsSending(false);
      setTimeout(() => {
        setResendMessage("");
      }, 3000);
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isButtonDisabled && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsButtonDisabled(false);
    }
    return () => clearInterval(interval);
  }, [isButtonDisabled, timer]);

  return (
    <div className={styles.messageContainer}>
      <h1 className={styles.messageHeader}>
        You are <br /> registered!
      </h1>
      <p className={styles.messageText}>
        Confirm your registration by clicking on the
        <br /> link from the email that was sent to the
        <br /> address you specified during registration
      </p>
      <button className={styles.button} onClick={handleButtonClick}>
        Go to your account <AuthButtonArrow />
      </button>
      <p
        className={styles.resendLink}
        onClick={handleResendClick}
        style={{
          cursor: isButtonDisabled ? "not-allowed" : "pointer",
          pointerEvents: isButtonDisabled ? "none" : "auto",
        }}
      >
        {isButtonDisabled
          ? `Resend will be available in ${timer}s`
          : isSending
            ? "Sending ..."
            : "Resend activation email"}
      </p>
      <p className={`${styles.resendMessage} ${styles[messageClass]}`}>
        {resendMessage && resendMessage}
      </p>
    </div>
  );
}

export default SuccessfulRegistration;
