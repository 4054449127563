import React from "react";
import styles from "./styles.module.scss";
import { aspects } from "@pages/Overview/mocks/aspect";

const Aspects: React.FC = () => {
  return (
    <div className={styles.aspects}>
      <div className={styles.headerBox}>
        <h3>Aspects</h3>
        <div className={styles.graphItems}>
          <p className={styles.positive}>positive</p>
          <p className={styles.neutral}>neutral</p>
          <p className={styles.negative}>negative</p>
        </div>
      </div>
      {aspects.map((aspect, index) => (
        <div key={index} className={styles.aspect}>
          <span className={styles.aspectName}>{aspect.name}</span>
          <div className={styles.bars}>
            <div
              className={styles.positive}
              style={{ width: `${aspect.positive}%` }}
            >
              {aspect.positive}%
            </div>
            <div
              className={styles.neutral}
              style={{ width: `${aspect.neutral}%` }}
            >
              {aspect.neutral}%
            </div>
            <div
              className={styles.negative}
              style={{ width: `${aspect.negative}%` }}
            >
              {aspect.negative}%
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Aspects;
