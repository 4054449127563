import React, { useState } from "react";
import styles from "./styles.module.scss";
import inputArrow from "@assets/icons/inputArrow.svg";
import ProductCode from "@components/ProductCode";
import ProductSelectionModal from "../ProductSelectionModal/index";

const ProductInfo: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <div className={styles.productInfo}>
      <div className={styles.productLabel}>
        <p>Your product</p>
      </div>
      <div className={styles.productDetails}>
        <div className={styles.productCode}>
          <div className={styles.styledInputContainer}>
            <input
              type="text"
              className={styles.styledInput}
              value="Zephyr Presrv 24 Inch Wine Fridge & Beverage Refrigerator Dual Zone Und..."
              readOnly
            />
            <span className={styles.dropdownIcon} onClick={openModal}>
              <img
                src={inputArrow}
                width={12}
                height={12}
                alt="Dropdown Icon"
              />
            </span>
          </div>
          <ProductCode code={"B07GWVP5RC"} />
        </div>
        <div className={styles.compareButtonBox}>
          <button className={styles.compareButton}>
            COMPARISON WITH TOP 5
          </button>
        </div>
      </div>
      <ProductSelectionModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default ProductInfo;
