import React from "react";
import styles from "./styles.module.scss";
import UserPopupArrow from "@assets/icons/userPopupArrow";
import { NavLink } from "react-router-dom";
import { ROUTES } from "src/router/routes";

interface RevenuePopupProps {
  closePopup: () => void;
}

export const RevenuePopup: React.FC<RevenuePopupProps> = ({ closePopup }) => {
  return (
    <div className={styles.popupMenu}>
      <div className={styles.popupContent}>
        <div className={styles.section}>
          <p>
            Summary <UserPopupArrow />
          </p>
          <ul>
            <li onClick={closePopup}>Brand Level Performance</li>
            <li onClick={closePopup}>Product Level Performance</li>
            <li onClick={closePopup}>Subscription Performance</li>
          </ul>
        </div>
        <div className={styles.section}>
          <p>
            Advertising <UserPopupArrow />
          </p>
          <ul></ul>
        </div>
        <div className={styles.section}>
          <p>
            Customers <UserPopupArrow />
          </p>
          <ul>
            <li onClick={closePopup}>
              <NavLink to={`${ROUTES.OVERVIEW}`}>ABSA Overview</NavLink>
            </li>
            <li onClick={closePopup}>ABSA Compare</li>
            <li onClick={closePopup}>Hub</li>
            <li onClick={closePopup}>Seller Feedback</li>
          </ul>
        </div>
        <div className={styles.section}>
          <p>Purchases Analysis</p>
          <ul>
            <li onClick={closePopup}>Repeat Purchases</li>
            <li onClick={closePopup}>Demographics</li>
            <li onClick={closePopup}>Device Analytics</li>
            <li onClick={closePopup}>Market Basket Analytics</li>
            <li onClick={closePopup}>Geographic Performance</li>
          </ul>
        </div>
        <div className={styles.section}>
          <p>
            Targeting <UserPopupArrow />
          </p>
          <ul></ul>
        </div>
        <div className={styles.section}>
          <p>
            Content <UserPopupArrow />
          </p>
          <ul></ul>
        </div>
        <div className={styles.section}>
          <p>
            Pricing <UserPopupArrow />
          </p>
          <ul></ul>
        </div>
      </div>
    </div>
  );
};
