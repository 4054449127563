import { AuthGuard } from "@components/AuthGuard/AuthGuard";
import { Home } from "@pages/Home/Home";

export const PrivatePage = () => {
  return (
    <AuthGuard>
      <Home />
    </AuthGuard>
  );
};
