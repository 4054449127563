import { useState } from "react";
import styles from "./styles.module.scss";
import Camera from "@assets/icons/camera";
import { useUserStore } from "src/store/user.state";
import WelcomeArrow from "@assets/icons/welcomeArrow";
import RightArrow from "@assets/icons/sliderBtn";
import { images } from "./mock";
import CustomSelect from "./CustomSelect";

interface WelcomeSectionProps {
  hideDropdown?: boolean;
}

export const WelcomeSection: React.FC<WelcomeSectionProps> = ({
  hideDropdown = false,
}) => {
  const [showContent, setShowContent] = useState(false);
  const { user } = useUserStore();
  const [visibleImages, setVisibleImages] = useState(images);

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  const moveRight = () => {
    setVisibleImages((prevImages) => {
      const newImages = [...prevImages];
      const firstImage = newImages.shift();
      newImages.push(firstImage);
      return newImages;
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.welcomeBox}>
        <div className={styles.welcomeText}>
          <p>{`${user.first_name}, get ready to scale`}</p>
          <div className={styles.videoBtn}>
            <button className={styles.buttonWrapper} onClick={toggleContent}>
              {showContent ? (
                <p>
                  Hide <WelcomeArrow />
                </p>
              ) : (
                <p>
                  Learn <Camera />
                </p>
              )}
            </button>
          </div>
        </div>
        {!hideDropdown && (
          <div className={styles.dropdown}>
            <CustomSelect />
          </div>
        )}
      </div>
      {showContent && (
        <div className={styles.additionalContent}>
          <div className={styles.cardsWrapper}>
            {visibleImages.map((image, index) => (
              <div key={index} className={styles.card}>
                <img src={image.src} alt={`Card ${index + 1}`} />
              </div>
            ))}
          </div>
          <button className={styles.rightArrow} onClick={moveRight}>
            <RightArrow />
          </button>
        </div>
      )}
    </div>
  );
};
