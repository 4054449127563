import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { CustomRouter, history } from "@services/router";
import "./locales";
import "./style/variables.css";
import "./style/fonts.css";
import "./style/main.less";
import App from "./App";
import { intercept } from "./utils/interceptor";
import { useConfigStore } from "./store/config.state";

intercept();

function initializeApp() {
  const config = useConfigStore.getState().config;
  if (config && config.sentry_dsn) {
    Sentry.init({
      environment: config.env_run,
      release: config.frontend_commit_hash,
      dsn: config.sentry_dsn,
      integrations: [
        Sentry.browserTracingIntegration({
          // @ts-ignore
          routingInstrumentation:
            // @ts-ignore
            Sentry.reactRouterV5BrowserTracingIntegration(history),
        }),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }

  ReactDOM.createRoot(document.getElementById("root")!).render(
    <CustomRouter history={history}>
      <App />
    </CustomRouter>,
  );
}

useConfigStore.getState().fetchConfig().then(initializeApp);
