export const metricsList = [
  { id: "1", title: "Orders" },
  { id: "2", title: "Total Sales" },
  { id: "3", title: "Units sold" },
  { id: "4", title: "Contribution profit" },
  { id: "5", title: "Organic CTR" },
  { id: "6", title: "Organic CVR" },
  { id: "7", title: "Review score" },
  { id: "8", title: "Value from Nyle" },
  { id: "9", title: "Total Sales" },
  { id: "10", title: "NTB Sales" },
  { id: "11", title: "Total Ad Sales" },
  { id: "12", title: "Contribution Profit" },
  { id: "13", title: "Subscription Sales" },
  { id: "14", title: "Total Sales" },
  { id: "15", title: "NTB Sales" },
  { id: "16", title: "Total Ad Spend" },
];
