import React, { useState } from "react";
import styles from "./styles.module.scss";
import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";
import { Widget } from "@pages/Dashboard/features/Widgets/Widget";

interface WidgetsListProps {
  widgets: IWidget[];
}

export const WidgetsList: React.FC<WidgetsListProps> = ({ widgets }) => {
  const initialWidgets = widgets.slice(0, 8);
  const [widgetList, setWidgetList] = useState(initialWidgets);
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
  const [hoveringIndex, setHoveringIndex] = useState<number | null>(null);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("widgetIndex", index.toString());
    setDraggingIndex(index);
  };

  const handleDrop = (e, dropIndex) => {
    const dragIndex = parseInt(e.dataTransfer.getData("widgetIndex"), 10);
    const tempList = [...widgetList];
    const draggedWidget = tempList[dragIndex];
    tempList.splice(dragIndex, 1);
    tempList.splice(dropIndex, 0, draggedWidget);
    setWidgetList(tempList);
    setDraggingIndex(null);
    setHoveringIndex(null);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setHoveringIndex(index);
  };

  const handleDragEnd = () => {
    setDraggingIndex(null);
    setHoveringIndex(null);
  };

  return (
    <div className={styles.widgetsList}>
      <div className={styles.gridBox}>
        {widgetList.map((widget, index) => (
          <div
            key={widget.id}
            className={`${styles.widgetItem} ${draggingIndex === index ? styles.dragging : ""} ${hoveringIndex === index ? styles.hovering : ""}`}
            draggable
            onDragStart={(e) => handleDragStart(e, index)}
            onDrop={(e) => handleDrop(e, index)}
            onDragOver={(e) => handleDragOver(e, index)}
            onDragEnd={handleDragEnd}
          >
            <Widget widget={widget} />
          </div>
        ))}
      </div>
    </div>
  );
};
