import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from "recharts";
import { format } from "date-fns";
import styles from "./styles.module.scss";

const data = [
  {
    date: new Date().getTime() - 5 * 30 * 24 * 3600 * 1000,
    reviewScore: 50,
    positiveReviews: 20,
    negativeReviews: 10,
    neutralReviews: 20,
  },
  {
    date: new Date().getTime() - 4 * 30 * 24 * 3600 * 1000,
    reviewScore: 55,
    positiveReviews: 25,
    negativeReviews: 12,
    neutralReviews: 18,
  },
  {
    date: new Date().getTime() - 3 * 30 * 24 * 3600 * 1000,
    reviewScore: 60,
    positiveReviews: 30,
    negativeReviews: 15,
    neutralReviews: 15,
  },
  {
    date: new Date().getTime() - 2 * 30 * 24 * 3600 * 1000,
    reviewScore: 58,
    positiveReviews: 28,
    negativeReviews: 14,
    neutralReviews: 18,
  },
  {
    date: new Date().getTime() - 30 * 24 * 3600 * 1000,
    reviewScore: 54,
    positiveReviews: 26,
    negativeReviews: 12,
    neutralReviews: 16,
  },
  {
    date: new Date().getTime(),
    reviewScore: 52,
    positiveReviews: 24,
    negativeReviews: 10,
    neutralReviews: 18,
  },
];

const convertDate = (timestamp: number) => {
  return format(new Date(timestamp), "MMM");
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.customTooltip}>
        <p className={styles.label}>{`Date : ${convertDate(label)}`}</p>
        {payload.map((entry, index) => (
          <p
            key={index}
            style={{ color: entry.color }}
          >{`${entry.name} : ${entry.value}%`}</p>
        ))}
      </div>
    );
  }

  return null;
};

const CustomLegend = ({ payload }) => {
  return (
    <div className={styles.customLegend}>
      {payload.map((entry, index) => (
        <div key={`item-${index}`} className={styles.legendItem}>
          <span
            className={styles.legendColor}
            style={{ backgroundColor: entry.color }}
          />
          <span className={styles.legendText}>{entry.value}</span>
        </div>
      ))}
    </div>
  );
};

export const ReviewChart = () => {
  return (
    <div className={styles.reviewScoreChart}>
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 0,
          }}
        >
          <Legend
            content={<CustomLegend payload={undefined} />}
            verticalAlign="top"
            align="center"
            wrapperStyle={{ paddingBottom: 10 }}
          />
          <CartesianGrid stroke="#444" strokeDasharray="3 3" />
          <XAxis dataKey="date" tickFormatter={convertDate} stroke="#7C7C78" />
          <YAxis type="number" domain={[0, 60]} stroke="#7C7C78" />
          <Tooltip
            content={
              <CustomTooltip
                active={undefined}
                payload={undefined}
                label={undefined}
              />
            }
          />
          <Line
            type="monotone"
            dataKey="reviewScore"
            stroke="#8884d8"
            strokeWidth={2}
            dot={false}
            name="Review score"
          />
          <Line
            type="monotone"
            dataKey="positiveReviews"
            stroke="#82ca9d"
            strokeWidth={2}
            dot={false}
            name="% of positive reviews"
          />
          <Line
            type="monotone"
            dataKey="negativeReviews"
            stroke="#ff7300"
            strokeWidth={2}
            dot={false}
            name="% of negative reviews"
          />
          <Line
            type="monotone"
            dataKey="neutralReviews"
            stroke="#888888"
            strokeWidth={2}
            dot={false}
            name="% of neutral reviews"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
