import React from "react";
import styles from "./styles.module.scss";

interface CustomCheckboxProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  checked,
  onChange,
}) => {
  return (
    <div className={styles.checkboxContainer}>
      <input
        type="checkbox"
        checked={checked}
        className={styles.checkbox}
        onChange={onChange}
      />
      <span className={styles.checkmark}></span>
    </div>
  );
};

export default CustomCheckbox;
