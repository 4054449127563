import "./index.css";
import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";
import { FC } from "react";
import classnames from "classnames";
import { convertValue } from "src/utils/convertUtil";
import WidgetIcon from "@assets/icons/widgetsDNDBtn";

interface IProps {
  widget: IWidget;
}

export const Widget: FC<IProps> = ({ widget }: IProps) => {
  const differenceClass = classnames(
    "dashboard-widget__difference",
    "text-button",
    widget.difference[0] === "-"
      ? "dashboard-widget__difference--negative"
      : widget.difference[0] === "+"
        ? "dashboard-widget__difference--positive"
        : "",
  );

  return (
    <div className="dashboard-widget">
      <header className="dashboard-widget__header">
        <span className="dashboard-widget__name heading-3">{widget.name}</span>
        <span className={differenceClass}>{widget.difference}</span>
        <div className="dashboard-widget__icon">
          <WidgetIcon />
        </div>
      </header>
      <footer className="dashboard-widget__footer">
        <span className="dashboard-widget__value body-large">
          {convertValue(widget.value)} <br />
        </span>
      </footer>
    </div>
  );
};
