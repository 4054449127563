import React, { useState } from "react";
import MetricItem from "@components/MetricItem";
import CustomPagination from "@components/CustomPagination";
import styles from "./styles.module.scss";
import { metricsList } from "@pages/Dashboard/mocks/metricsList";

interface MetricsListProps {
  searchQuery: string;
  onAddMetric: (metric: any) => void;
  currentMetrics: any[];
}

const MetricsList: React.FC<MetricsListProps> = ({
  searchQuery,
  onAddMetric,
  currentMetrics,
}) => {
  const [page, setPage] = useState<number>(1);

  const itemsPerPage = 18;

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const filteredItems = metricsList.filter((metric) =>
    metric.title.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const currentItems = filteredItems.slice(startIndex, endIndex);

  const columns = [];
  for (let i = 0; i < currentItems.length; i += 6) {
    columns.push(currentItems.slice(i, i + 6));
  }

  return (
    <div className={styles.metricsContent}>
      <h5>All metrics</h5>
      <div className={styles.metricList}>
        {columns.map((column, columnIndex) => (
          <div key={columnIndex} className={styles.metricColumn}>
            {column.map((metric) => (
              <MetricItem
                key={metric.id}
                metric={metric}
                checked={currentMetrics.some((item) => item.id === metric.id)}
                onChange={() => onAddMetric(metric)}
              />
            ))}
          </div>
        ))}
      </div>
      <CustomPagination
        current={page}
        onChange={onPageChange}
        total={filteredItems.length}
        pageSize={itemsPerPage}
      />
    </div>
  );
};

export default MetricsList;
