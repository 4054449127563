import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ROUTES } from "src/router/routes.ts";
import { RevenuePopup } from "./RevenuePopup";

type NavItem = {
  text: string;
  url?: string;
  onClick?: () => void;
};

export const HeaderNav = () => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const navItems: NavItem[] = [
    { text: t("label.dashboard"), url: `${ROUTES.ROOT}` },
    { text: t("label.my_products"), url: `products` },
    {
      text: t("revenue"),
      onClick: () => {
        setMenuOpen(!menuOpen);
      },
    },
    { text: t("finance"), url: `${ROUTES.FINANCE}` },
    { text: t("Supply chain"), url: `supply-chain` },
    { text: t("Market intelligence"), url: `market-intelligence` },
    { text: t("Audit"), url: `audit` },
  ];

  const navItemsJSX = navItems.map((item: NavItem, index) => (
    <React.Fragment key={index}>
      {item.url ? (
        <NavLink to={item.url} className="app-header__nav-item text-button">
          {item.text}
        </NavLink>
      ) : (
        <div
          className="revenue"
          style={{ position: "relative" }}
          ref={popupRef}
        >
          <div
            className={`app-header__nav-button text-button ${menuOpen ? `activeRevenue` : ``}`}
            onClick={item.onClick}
          >
            {item.text}
          </div>
          {menuOpen && <RevenuePopup closePopup={() => setMenuOpen(false)} />}
        </div>
      )}
    </React.Fragment>
  ));

  return <nav className="app-header__nav">{navItemsJSX}</nav>;
};
