import { Button } from "antd";
import styles from "./styles.module.scss";
import { useState, useEffect, useRef } from "react";
import CloseModal from "src/assets/icons/closeModal.svg";
import SearchIcon from "src/assets/icons/SearchIcon.svg";
import GridLayout from "react-grid-layout";
import MetricsList from "./metricsList";
import greySquare from "src/assets/icons/greySquare.svg";

interface ConfigModalProps {
  buttonWidth: any;
  closeModal: any;
}

export const ConfigModal = ({ buttonWidth, closeModal }: ConfigModalProps) => {
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [layout, setLayout] = useState([]);

  const previousLayout = useRef(layout);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleAddMetric = (metric) => {
    setItems((prevItems) => {
      const exists = prevItems.some((item) => item.id === metric.id);
      if (exists) {
        return prevItems.filter((item) => item.id !== metric.id);
      } else {
        return [...prevItems, metric];
      }
    });
    setLayout((prevLayout) => [
      ...prevLayout,
      {
        i: metric.id,
        x: prevLayout.length % 4,
        y: Math.floor(prevLayout.length / 4),
        w: 1,
        h: 1,
      },
    ]);
  };

  const handleRemoveMetric = (id: any) => {
    setItems((prevItems) => prevItems.filter((item) => item.id !== id));
    setLayout((prevLayout) => prevLayout.filter((item) => item.i !== id));
  };

  const handleLayoutChange = (newLayout) => {
    if (JSON.stringify(newLayout) !== JSON.stringify(previousLayout.current)) {
      setLayout(newLayout);
      previousLayout.current = newLayout;
    }
  };

  useEffect(() => {
    if (JSON.stringify(layout) !== JSON.stringify(previousLayout.current)) {
      previousLayout.current = layout;
    }
  }, [layout]);

  return (
    <div className={styles.container}>
      <div className={styles.rumpBox}>
        <div
          className={styles.rump}
          style={{
            width: `calc(30% - ${Math.round(buttonWidth)}px)`,
            marginLeft: "auto",
            marginRight: "-20px",
          }}
        />
        <div
          className={styles.divider}
          style={{ width: `${Math.round(buttonWidth + 20)}px` }}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <p className={styles.title}>Current Metrics</p>
          <p className={styles.close} onClick={closeModal}>
            <img
              width={8}
              height={8}
              src={CloseModal}
              alt="Close"
              className={styles.cancelIcon}
            />
            cancel
          </p>
        </div>
        <div className={styles.gridBox}>
          <GridLayout
            className={styles.dashboard}
            layout={layout}
            cols={4}
            rowHeight={115}
            width={550}
            onLayoutChange={handleLayoutChange}
            compactType="horizontal"
            useCSSTransforms={false}
          >
            {items.map((item) => (
              <div
                key={item.id}
                data-grid={{
                  i: item.id,
                  x: layout.findIndex((l) => l.i === item.id) % 4,
                  y: Math.floor(layout.findIndex((l) => l.i === item.id) / 4),
                  w: 1,
                  h: 1,
                }}
              >
                <div className={styles.dashboardItem}>
                  <div className={styles.itemContent}>
                    <div className={styles.itemContentHeader}>
                      <div className={styles.itemDragHandle}>
                        <img src={greySquare} width={14} height={14} />
                      </div>
                      <div
                        className={styles.itemClose}
                        onClick={() => handleRemoveMetric(item.id)}
                      >
                        <span className={styles.closeIcon}></span>
                      </div>
                    </div>
                    <div className={styles.itemContentName}>{item.title}</div>
                  </div>
                </div>
              </div>
            ))}
          </GridLayout>
        </div>
        <div className={styles.metrics}>
          <div className={styles.metricsHeader}>
            <p className={styles.title}>Add Metrics</p>
            <div className={styles.searchContainer}>
              <input
                type="text"
                className={styles.searchInput}
                placeholder="Search by name"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <img
                src={SearchIcon}
                alt="Search"
                className={styles.searchIcon}
              />
            </div>
          </div>
          <MetricsList
            searchQuery={searchQuery}
            onAddMetric={handleAddMetric}
            currentMetrics={items}
          />
        </div>
      </div>
      <Button
        type="primary"
        shape="round"
        className={styles.applyBtn}
        onClick={closeModal}
      >
        Apply
      </Button>
    </div>
  );
};
