import { useCallback, useEffect, useState } from "react";
import { Button, Drawer, List } from "antd";
import { useConfigStore } from "src/store/config.state";
import "./nyle-config.less";

export const NyleConfig = () => {
  const { config, fetchConfig } = useConfigStore();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!config) {
      fetchConfig();
    }
  }, [config, fetchConfig]);

  const showDrawer = useCallback(() => setVisible(true), []);
  const onClose = useCallback(() => setVisible(false), []);

  if (!config) {
    return null;
  }

  if (config.env_run == "production") {
    return null;
  }

  return (
    <div className="nyle-config">
      <Button size="small" type="primary" onClick={showDrawer}>
        Debug Info
      </Button>
      {visible && (
        <Drawer
          title="Env params"
          placement="right"
          visible={visible}
          onClose={onClose}
        >
          <List
            bordered
            dataSource={Object.entries(config).filter(
              ([, value]) => typeof value === "string",
            )}
            renderItem={([key, value]) => (
              <List.Item key={key}>
                <div>
                  {key} : {value}
                </div>
              </List.Item>
            )}
          />
        </Drawer>
      )}
    </div>
  );
};
