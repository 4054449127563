import React from "react";
import styles from "./styles.module.scss";
import UserPopupArrow from "@assets/icons/userPopupArrow";
import { ROUTES } from "src/router/routes";
import { Link } from "react-router-dom";

interface SettingsPopupProps {}

export const SettingsPopup: React.FC<SettingsPopupProps> = () => {
  return (
    <div className={styles.popupMenu}>
      <div className={styles.popupContent}>
        <ul>
          <li>
            <span>
              Settings <UserPopupArrow />
            </span>
          </li>
          <li>
            <span>
              Plans & Billing <UserPopupArrow />
            </span>
          </li>
          <li>
            <Link to={`/${ROUTES.CONNECT_AMAZON}`}>
              Connections <UserPopupArrow />
            </Link>
          </li>
          <li>
            <span>
              Notification Settings <UserPopupArrow />
            </span>
          </li>
          <li>
            <span>
              Become an Affiliate <UserPopupArrow />
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};
