import React from "react";
import styles from "./styles.module.scss";
import ProductCode from "@components/ProductCode";
import MockImg from "@assets/img/performance.png";
import CompareImg from "@assets/img/compare.svg";

interface Product {
  title: string;
  rating: number;
  code: string;
}

interface ProductCardProps {
  product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  return (
    <div className={styles.productCard}>
      <img src={MockImg} alt={product.title} className={styles.productImage} />
      <div className={styles.productInfo}>
        <p className={styles.productTitle}>{product.title}</p>
        <ProductCode code={product.code} />
        <p className={styles.productRating}>
          <span>⭐ {product.rating}</span>
        </p>
        <button className={styles.compareButton}>
          COMPARE <img src={CompareImg} />
        </button>
      </div>
    </div>
  );
};

export default ProductCard;
