import QuestionCircle from "@assets/icons/question-circle.tsx";
import Notifications from "@assets/icons/notifications.tsx";
import Sun from "@assets/icons/sun.tsx";
import Settings from "@assets/icons/setting";
import { useEffect, useRef, useState } from "react";
import { SettingsPopup } from "./SettingsPopup";

export const HeaderActions = () => {
  const [activeButton, setActiveButton] = useState<string | null>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleButton = (buttonName: string) => {
    setActiveButton((prevActiveButton) =>
      prevActiveButton === buttonName ? null : buttonName,
    );
  };

  const getIconColor = (buttonName: string) => {
    return activeButton === buttonName ? "#7C7C78" : "currentColor";
  };

  return (
    <div className="header-actions">
      <button
        className={`header-actions__button ${activeButton === "questionCircle" ? "activeBtn" : ""}`}
        onClick={() => toggleButton("questionCircle")}
      >
        <QuestionCircle color={getIconColor("questionCircle")} />
      </button>
      <button
        className={`header-actions__button ${activeButton === "notifications" ? "activeBtn" : ""}`}
        onClick={() => toggleButton("notifications")}
      >
        <Notifications color={getIconColor("notifications")} />
      </button>

      <button
        className={`header-actions__button ${activeButton === "sun" ? "activeBtn" : ""}`}
        onClick={() => toggleButton("sun")}
      >
        <Sun color={getIconColor("sun")} />
      </button>
      <div
        ref={popupRef}
        className={`header-actions__button ${menuOpen ? "activeBtn" : ""}`}
        onClick={() => {
          toggleButton("settings");
          setMenuOpen(!menuOpen);
        }}
      >
        <Settings />
        {menuOpen && <SettingsPopup />}
      </div>
    </div>
  );
};
