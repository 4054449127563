import Axios from "axios";

const COMMIT_HASH = import.meta.env.BUILD_COMMIT_HASH || "";

export interface IConfig {
  env_config: string;
  sentry_dsn: string;
  env_run: string;
  frontend_commit_hash: string;
  commit_hash: string;
  zendesk_key: string;
}

export class ConfigService {
  public static async fetchConfig(): Promise<IConfig | null> {
    try {
      const response = await Axios.get<IConfig>("/api/v1/config");
      const config = response.data;

      return {
        ...config,
        frontend_commit_hash: COMMIT_HASH,
      };
    } catch (error) {
      console.error("Error fetching config:", error);

      return {
        env_config: "",
        sentry_dsn: "",
        env_run: "",
        frontend_commit_hash: COMMIT_HASH,
        commit_hash: "",
        zendesk_key: "",
      };
    }
  }
}
