import { useEffect, useState } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "./calendar.scss";
import { DateRangePicker } from "react-date-range";
import { useOutsideClick } from "src/utils/useOutsideClick";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import closeModalIcon from "@assets/icons/closeModal.svg";
import { presets } from "./calendarPreset";

interface CalendarModalProps {
  buttonWidth: any;
  closeModal: any;
  onDateChange: (startDate: Date, endDate: Date) => void;
}

export const CalendarModal = ({
  buttonWidth,
  closeModal,
  onDateChange,
}: CalendarModalProps) => {
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [selectedPreset, setSelectedPreset] = useState<string | null>(null);

  const modalRef = useOutsideClick(() => {
    closeModal();
  });

  useEffect(() => {
    document.querySelectorAll(".rdrWeekDays").forEach((weekDays) => {
      weekDays.querySelectorAll(".rdrWeekDay").forEach((day, index) => {
        const days = ["S", "M", "T", "W", "T", "F", "S"];
        day.textContent = days[index];
      });
    });
  }, []);

  const handleSelect = (ranges: any) => {
    setSelectionRange(ranges.selection);
    setSelectedPreset(null);
  };

  const applyPreset = (preset: any) => {
    setSelectionRange(preset.range);
    setSelectedPreset(preset.label);
  };

  const applyDateSelection = () => {
    onDateChange(selectionRange.startDate, selectionRange.endDate);
    closeModal();
  };

  return (
    <div ref={modalRef} className="modalContainer">
      <button className="closeButton" onClick={closeModal}>
        <img src={closeModalIcon} alt="Close" />
        Cancel
      </button>
      <div className="rumpBox">
        <div
          className="rump"
          style={{
            width: `calc(30% - ${Math.round(buttonWidth)}px)`,
            marginLeft: "auto",
            marginRight: "-20px",
          }}
        />
        <div
          className="divider"
          style={{ minWidth: `${Math.round(buttonWidth + 20)}px` }}
        />
      </div>
      <div className="calendarContainer">
        <div>
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={handleSelect}
            months={3}
            direction="horizontal"
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            className="calendar"
          />
          <button className="applyButton" onClick={applyDateSelection}>
            APPLY
          </button>
        </div>

        <div className="presetsContainer">
          <div className="presetsBox">
            <div className="title">Preset</div>
            {presets.map((preset, index) => (
              <button
                className={`presetsBtn ${selectedPreset === preset.label ? "selected" : ""}`}
                key={index}
                onClick={() => applyPreset(preset)}
              >
                {preset.label}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
