export const productSelectionMocks = [
  {
    name: "Zephyr Presrv 24 Inch Wine Fridge & Beverage Refrigerator Dual Zone",
    rating: 3.3,
    asin: "B07GWP5RC",
    image: "path-to-image-1",
  },
  {
    name: "Zephyr 24 Wine Fridge & Beverage Refrigerator Single ",
    rating: 3.4,
    asin: "B08BMVSFYZ",
    image: "path-to-image-2",
  },
  {
    name: "Zephyr Brisas 24 Inch Wine Fridge Zone Under Counter",
    rating: 3.8,
    asin: "B08SJBNB1W",
    image: "path-to-image-3",
  },
  {
    name: "Zephyr Presrv 24 Inch Wine Fridge Single Zone Under Counter",
    rating: 3.6,
    asin: "B07YYMZ1PF",
    image: "path-to-image-4",
  },
];
