import { create } from "zustand";
import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";

export interface IDashboardState {
  widgets: IWidget[];
  setWidgets: (widgets: IWidget[]) => void;
  widgetsLoaded: boolean;
  reset: () => void;
}
export const useDashboardStore = create<IDashboardState>((set) => ({
  widgets: [],
  widgetsLoaded: false,
  setWidgets: (widgets: IWidget[]) => {
    return set((): Partial<IDashboardState> => {
      return {
        widgets,
        widgetsLoaded: true,
      };
    });
  },
  reset: () => {
    return set((): Partial<IDashboardState> => {
      return {
        widgets: [],
        widgetsLoaded: false,
      };
    });
  },
}));
